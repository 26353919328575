import React from 'react'
import {Container,Header,Titleh1,Description,Body,Titleh3,TitleP,Ul,Li} from './SubmissionElements'

function Submission() {
    return (
       <Container>
           <Header>
               <Titleh1>
                   Submission Guidelines
               </Titleh1>
               <Description>
               Experttell Media is exclusively representing the finest non-fiction authors and artists. While we have an established client list, we continue to seek new talent that will help us innovate the literary industry. We accept submissions from both published and aspiring authors and artists.
               </Description>
           </Header>
           <Body>
              <Titleh3>
                 Submission Process
              </Titleh3>
              <Description>We take submission only through this email <span>managers@experttell.com</span></Description>
              
              <TitleP>Please email the following:</TitleP>
              <Ul>
                 
                 <Li>Querry Letter</Li>
                 <Li>Credentials</Li>  
                 <Li>Explanation of what makes your book unique and special</Li>
                 <Li>Synopsis</Li>
                 <Li>Website and Social Media Links(if any)</Li>
                 
              </Ul>

              <TitleP>
              On the email please attach:
              </TitleP>
              <Ul>
                 <Li>Complete Manuscript as a DOC or PDF file.</Li> 
              </Ul>

               <Titleh3>
               RESPONSE TIME
               </Titleh3>
               <Description>
               We strive to review submissions quickly. Due to the number of submissions we receive each year we can no longer respond to everyone. Please understand that we receive over 10,000 submissions each year. Once we've reviewed your work, if we are interested in discussing possible representation, we will contact you by phone or email - typically within 8-10 weeks. 

Once you've received an offer for representation or publication, please follow up immediately. If we don't receive any response from you within 1-2 weeks. We will move to the next candidate. 

               </Description>
           </Body>
       </Container>
    )
}

export default Submission
