import * as React from "react"
import Seo from "../components/seo"
import Submission from "../components/sections/submission/Submission"
import { Helmet } from "react-helmet"
import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"

const SubmissionPage = () => (
  <>
    <Seo title="Submission" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Submission/>
    <NewsLetter/>
  </>
)

export default SubmissionPage
