import styled from 'styled-components'

export const Container = styled.div`
display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  ${"" /* align-items:center; */}
  padding: 4rem calc((100vw - 1300px) / 2);
`
export const Header = styled.div`
  margin-bottom: 1rem;
  color: #111;
  display:flex;
  flex-direction:column;

  @media screen and (max-width: 983px){
    text-align: start;
    justify-content:flex-start;
    align-items:flex-start;
  }
`
export const Titleh1 = styled.h1`
 font-size: clamp(1.5rem, 5vw, 2rem);
  color: ${props => props.theme.colors.dark1};
  padding: 0 3rem;
  margin-bottom: 1rem;
  font-weight: 700;
`
export const Description = styled.p`
 font-size: clamp(.7rem, 5vw, .9rem);
  padding: 0 3rem;
  font-family: 'Roboto', sans-serif;
  color: ${props => props.theme.colors.dark2};
  max-width:100%;
  line-height:1.5;
  margin-bottom:1rem;

  span{
      color: ${props => props.theme.colors.main1};
      font-weight: 700;
  }
  @media screen and (max-width: 983px){
    max-width:100%
  }
`
export const Body = styled.div`
display:flex;
  flex-direction:column;
  ${'' /* padding: 0 3rem; */}
`
export const Titleh3 = styled.h3`
 font-size: clamp(1rem, 5vw, 1.4rem);
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.dark1};
  font-weight: 700;
  padding: 0 3rem;
`
export const TitleP = styled.p`
font-size: clamp(.8rem, 5vw, 1.1rem);
padding: 0 3rem;
font-weight:600;
margin-bottom:.5rem;
`
export const Ul = styled.ul`
padding: 0 5rem;
margin-bottom:1rem;
`
export const Li = styled.li`
margin-bottom:0.5rem;
font-size: clamp(.7rem, 5vw, .9rem);
  font-family: 'Roboto', sans-serif;
  color: ${props => props.theme.colors.dark2};
  max-width:100%;
  line-height:1.5;
`